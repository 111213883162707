<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col">{{ $t('tablasNutricionales.energia_y_nutrientes') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.unidades') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.cada100G') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.cada100ml' , {pv:"14,5"} ) }}</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>Kcal</td>
        <td>692</td>
        <td>100</td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>Kj</td>
        <td>2895</td>
        <td>418</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>g</td>
        <td>2,7</td>
        <td>0,4</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>g</td>
        <td>14,6</td>
        <td>2,1</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>g</td>
        <td>69,2</td>
        <td>10</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>g</td>
        <td>24</td>
        <td>3,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_monoinsaturadas') }}</td>
        <td>g</td>
        <td>19</td>
        <td>2,8</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_poliinsaturadas') }}</td>
        <td>g</td>
        <td>24</td>
        <td>3,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_linoleico_la') }}</td>
        <td>g</td>
        <td>22</td>
        <td>3,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_linolenico_ala') }}</td>
        <td>g</td>
        <td>2,2</td>
        <td>0,32</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.dha') }}</td>
        <td>mg</td>
        <td>133</td>
        <td>19</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.ara') }}</td>
        <td>mg</td>
        <td>137</td>
        <td>20</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>g</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>g</td>
        <td>5,5</td>
        <td>0,8</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="4">{{ $t('tablasNutricionales.vitaminas') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_a') }}</td>
        <td>mcg RE</td>
        <td>234</td>
        <td>34</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_d') }}</td>
        <td>mcg</td>
        <td>15</td>
        <td>2,1</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_e') }}</td>
        <td>mg alfa TE</td>
        <td>17</td>
        <td>2,4</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_k') }}</td>
        <td>mcg</td>
        <td>42</td>
        <td>6,1</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.tiamina_vit_b_1') }}</td>
        <td>mg</td>
        <td>0,98</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.riboflavina_vit_b_2') }}</td>
        <td>mg</td>
        <td>0,98</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.niacina_vit_b_3') }}</td>
        <td>mg</td>
        <td>3,5</td>
        <td>0,51</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_pantotenico') }}</td>
        <td>mg</td>
        <td>4,1</td>
        <td>0,59</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.piridoxina_vit_b_6') }}</td>
        <td>mg</td>
        <td>1</td>
        <td>0,15</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_folico') }}</td>
        <td>mcg</td>
        <td>105</td>
        <td>15</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cianocobalamina_vit_b_12') }}</td>
        <td>mcg</td>
        <td>1,8</td>
        <td>0,26</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.biotina') }}</td>
        <td>mcg</td>
        <td>26</td>
        <td>3,7</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colina') }}</td>
        <td>mg</td>
        <td>61</td>
        <td>8,8</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_c') }}</td>
        <td>mg</td>
        <td>261</td>
        <td>38</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.l_carnitina') }}</td>
        <td>mg</td>
        <td>43</td>
        <td>6,2</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.taurina') }}</td>
        <td>mg</td>
        <td>36</td>
        <td>5,2</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="4">{{ $t('tablasNutricionales.minerales') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>mg</td>
        <td>536</td>
        <td>78</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.potasio') }}</td>
        <td>mg</td>
        <td>597</td>
        <td>87</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cloro') }}</td>
        <td>mg</td>
        <td>911</td>
        <td>132</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.calcio') }}</td>
        <td>mg</td>
        <td>735</td>
        <td>107</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fosforo') }}</td>
        <td>mg</td>
        <td>440</td>
        <td>64</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.magnesio') }}</td>
        <td>mg</td>
        <td>113</td>
        <td>16</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.hierro') }}</td>
        <td>mg</td>
        <td>8,7</td>
        <td>1,3</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.zinc') }}</td>
        <td>mg</td>
        <td>4,9</td>
        <td>0,7</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cobre') }}</td>
        <td>mcg</td>
        <td>579</td>
        <td>84</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.manganeso') }}</td>
        <td>mg</td>
        <td>0,96</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.molibdeno') }}</td>
        <td>mcg</td>
        <td>33</td>
        <td>4,8</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.selenio') }}</td>
        <td>mcg</td>
        <td>32</td>
        <td>4,6</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cromo') }}</td>
        <td>mcg</td>
        <td>17</td>
        <td>2,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.iodo') }}</td>
        <td>mcg</td>
        <td>90</td>
        <td>13</td>
      </tr>
      <tr>
        <td colspan="4">{{ $t('tablasNutricionales.osmolaridad', {osmolaridad: 170, solutos:227}) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableCetogenik",
};
</script>
