<template>
  <div id="cetogenik">
    <Header
      imgUrl="header-dietacetogenica.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_dc')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Lata_Cetogenik.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <Sabor color="#e6e7e8" :texto="$t('productos.saborNeutro')" />

            <span class="mt-2">
              {{ $t("productos.presentacionLata", { gr: 200 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p class="pt-3" v-html="$t('productos.cetogenik.descripcion')"></p>
          </div>

          <div class="row">
            <div class="col-md-8">
              <CaracteristicasNutricionales
                :columnas="1"
                :caracteristicas="caracteristicas"
              />

              <div class="row mt-3">
                <div
                  class="
                    col-md-5
                    d-flex
                    justify-content-around
                    align-items-left
                  "
                >
                  <div>{{ $t("productos.alergenos") }}</div>
                  <div>
                    <img
                      :src="getIcon('icono-leche.png')"
                      class="img-fluid alergenos"
                    />
                  </div>
                  <div>
                    <img
                      :src="getIcon('icono-soja.png')"
                      class="img-fluid alergenos"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 perfil">
              <img
                :src="getIcon('icono-perfil-cetogenik.png')"
                class="img-fluid "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableCetogenik />
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container">
      <ModoPreparacion
        :texto="$t('productos.modoPreparacion.texto')"
        :textoOrientativo="
          $t('productos.cetogenik.textoOrientativo', {
            porciones: '',
            gr: '',
            ml: '',
          })
        "
        :tabla="this.$i18n.locale == 'en' ? tablaEn : tablaEs"
        medida=""
      />
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="[
        'medium-chain-lipids',
        'medium-chain-lipids-oil',
        'nutricion-clinica-pediatricos',
        'modulos-nutricionales',
        'espesante-resist',
      ]"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sabor from "@/components/elementos/Sabor.vue";
import CaracteristicasNutricionales from "@/components/CaracteristicasNutricionales.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";
import TableCetogenik from "@/components/tablas/TableCetogenik.vue";
import ModoPreparacion from "@/components/ModoPreparacion.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";

export default {
  name: "Cetogenik",
  components: {
    Header,
    Sabor,
    CaracteristicasNutricionales,
    TableCetogenik,
    ModoPreparacion,
    CardsRecomendados,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      caracteristicas: {
        en: en.productos.cetogenik.caracteristicas,
        es: es.productos.cetogenik.caracteristicas,
      },
      tablaEs: es.productos.cetogenik.tablaPreparacion,
      tablaEn: en.productos.cetogenik.tablaPreparacion,
    };
  },
  methods: {
    getIcon(fileName) {
      return require("../../assets/images/icons/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
